import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextAddressStates, TransitionAddressToState } from '../generated/ui-types'
import { GET_NEXT_ADDRESS_STATES, TRANSITION_ADDRESS_TO_STATE } from './address-state-data.graphql'

@Injectable()
export class AddressStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query<GetNextAddressStates.Query, GetNextAddressStates.Variables>(
            GET_NEXT_ADDRESS_STATES,
            {
                id,
            },
        )
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate<TransitionAddressToState.Mutation, TransitionAddressToState.Variables>(
            TRANSITION_ADDRESS_TO_STATE,
            {
                id,
                state,
            },
        )
    }
}

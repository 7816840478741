import { NgModule } from '@angular/core'
import { addNavMenuItem } from '@vendure/admin-ui/core'

import { EbicsSharedModule } from './ebics-shared.module'

@NgModule({
    imports: [EbicsSharedModule],
    declarations: [],
    providers: [
        addNavMenuItem(
            {
                id: 'ebics',
                label: 'Payment Issues',
                routerLink: ['/extensions/ebics'],
                icon: 'sad-face',
                requiresPermission: 'UpdateOrder',
            },
            'sales',
        ),
    ],
    exports: [],
})
export class EbicsUiExtensionModule {}

import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextKycStates, TransitionKycToState } from '../generated/ui-types'
import { GET_NEXT_KYC_STATES, TRANSITION_KYC_TO_STATE } from './kyc-state-data.graphql'

@Injectable()
export class KycStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query<GetNextKycStates.Query, GetNextKycStates.Variables>(GET_NEXT_KYC_STATES, {
            id,
        })
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate<TransitionKycToState.Mutation, TransitionKycToState.Variables>(
            TRANSITION_KYC_TO_STATE,
            {
                id,
                state,
            },
        )
    }
}

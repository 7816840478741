import { NgModule } from '@angular/core'
import { SharedModule } from '@vendure/admin-ui/core'

import { ReviewStateLabelComponent } from './components/review-state-label/review-state-label.component'

@NgModule({
    imports: [SharedModule],
    declarations: [ReviewStateLabelComponent],
    exports: [ReviewStateLabelComponent, SharedModule],
})
export class TestimonialsSharedModule {}

import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextAgeStates, TransitionAgeToState } from '../generated/ui-types'
import { GET_NEXT_AGE_STATES, TRANSITION_AGE_TO_STATE } from './age-state-data.graphql'

@Injectable()
export class AgeStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query<GetNextAgeStates.Query, GetNextAgeStates.Variables>(GET_NEXT_AGE_STATES, {
            id,
        })
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate<TransitionAgeToState.Mutation, TransitionAgeToState.Variables>(
            TRANSITION_AGE_TO_STATE,
            {
                id,
                state,
            },
        )
    }
}

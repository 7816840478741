import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { DataService, FormInputComponent, ModalService, NotificationService } from '@vendure/admin-ui/core'
import { EMPTY, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { CustomFieldConfig, Product } from '../generated/ui-types'
import { ProductStateSelectDialogComponent } from '../product-state-select-dialog/product-state-select-dialog.component'
import { ProductStateDataService } from '../providers/product-state-data.service'

@Component({
    templateUrl: './product-state-field.component.html',
    styleUrls: ['./product-state-field.component.scss'],
})
export class ProductStateFieldComponent implements FormInputComponent, OnInit {
    readonly: boolean
    nextStates$: Observable<string[]>
    config: Observable<CustomFieldConfig>
    entityId: string

    formControl: FormControl

    constructor(
        private dataService: DataService,
        private productStateDataService: ProductStateDataService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
    ) {}

    async manuallyTransitionToState() {
        return this.modalService
            .fromComponent(ProductStateSelectDialogComponent, {
                locals: {
                    nextStates: this.nextStates$,
                    cancellable: true,
                    message:
                        'Manually transition the product to another state. Note that order states are governed by rules which may prevent certain transitions.',
                },
                closable: true,
                size: 'md',
            })
            .pipe(
                switchMap(result => {
                    if (result) {
                        return this.transitionToStateOrThrow(this.entityId, result)
                    } else {
                        return EMPTY
                    }
                }),
            )
            .subscribe()
    }
    private transitionToStateOrThrow(orderId: string, state: string) {
        return this.productStateDataService.transitionToState(orderId, state).pipe(
            map(({ transitionProductToState }) => {
                switch (transitionProductToState?.__typename) {
                    case 'Product':
                        return (transitionProductToState as Product)?.customFields?.state
                    case 'ProductStateTransitionError':
                        this.notificationService.error(transitionProductToState?.transitionError)
                        throw new Error(transitionProductToState?.transitionError)
                }
            }),
        )
    }

    ngOnInit() {
        this.nextStates$ = this.route.data.pipe(
            switchMap(data => data.entity),
            switchMap((entity: any) => {
                this.entityId = entity.id
                return this.productStateDataService.getNextStates(entity.id as string).mapStream(data => {
                    return data.nextProductStates
                })
            }),
        )
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesUiExtensionModule } from './extensions/97c899af43950bd3ad402087607d95a3d5b59be384a98b3f7a810fc645cb36fc/pages-ui-extension.module';
import { EbicsUiExtensionModule } from './extensions/0b21dd28a2ddcd59c92ff26df3d5e7a8c77633e16582decc12cc0a3385e3044e/ebics-ui-extension.module';
import { ProductUiExtensionModule } from './extensions/aeda919e20a8bdabf47a6edeb885bb26ebb1925caf3cbdb3f629eccf18ca81c5/product-ui-extension.module';
import { CustomerUiExtensionModule } from './extensions/e2ad2732ea640505d438f38e118bd1fa9b26d4d30e801635efc12d527d0f589f/customer-ui-extension.module';
import { TestimonialsUiExtensionModule } from './extensions/b026a5f4252ad2e29708ccdf64dafcd52e61aa2a407bc2b86b1990c86c068afb/testimonials-ui-extension.module';
import { ReservationsUiExtensionModule } from './extensions/b26904ca9b415f1e507de29b11cc1645bd00177018bf088d88a3873dfbecb229/reservations-ui-extension.module';
import { OrderUiExtensionModule } from './extensions/9d77d548b3fd87c25bcd6246b79f662002b239972f54b19807d543c9d3dba752/order-ui-extension.module';


@NgModule({
    imports: [CommonModule, PagesUiExtensionModule, EbicsUiExtensionModule, ProductUiExtensionModule, CustomerUiExtensionModule, TestimonialsUiExtensionModule, ReservationsUiExtensionModule, OrderUiExtensionModule],
})
export class SharedExtensionsModule {}

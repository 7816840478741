import gql from 'graphql-tag'

export const GET_NEXT_OPENSANCTIONS_STATES = gql`
    query GetNextOpenSanctionsStates($id: ID!) {
        nextOpenSanctionsStates(id: $id)
    }
`

export const TRANSITION_OPENSANCTIONS_TO_STATE = gql`
    mutation TransitionOpenSanctionsToState($id: ID!, $state: String!) {
        transitionOpenSanctionsToState(id: $id, state: $state) {
            ... on Customer {
                id
                customFields {
                    openSanctionsState
                }
            }
            ... on OomniumCustomerStateTransitionError {
                errorCode
                message
                transitionError
                fromState
                toState
            }
        }
    }
`

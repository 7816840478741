import gql from 'graphql-tag'

export const GET_NEXT_ADDRESS_STATES = gql`
    query GetNextAddressStates($id: ID!) {
        nextAddressStates(id: $id)
    }
`

export const TRANSITION_ADDRESS_TO_STATE = gql`
    mutation TransitionAddressToState($id: ID!, $state: String!) {
        transitionAddressToState(id: $id, state: $state) {
            ... on Customer {
                id
                customFields {
                    addressState
                }
            }
            ... on OomniumCustomerStateTransitionError {
                errorCode
                message
                transitionError
                fromState
                toState
            }
        }
    }
`

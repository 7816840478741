import gql from 'graphql-tag'

export const GET_NEXT_AGE_STATES = gql`
    query GetNextAgeStates($id: ID!) {
        nextAgeStates(id: $id)
    }
`

export const TRANSITION_AGE_TO_STATE = gql`
    mutation TransitionAgeToState($id: ID!, $state: String!) {
        transitionAgeToState(id: $id, state: $state) {
            ... on Customer {
                id
                customFields {
                    ageState
                }
            }
            ... on OomniumCustomerStateTransitionError {
                errorCode
                message
                transitionError
                fromState
                toState
            }
        }
    }
`

import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { FormInputComponent } from '@vendure/admin-ui/core'
import { DefaultFormComponentConfig } from '@vendure/common/lib/shared-types'

@Component({
    selector: 'ck-editor-form-input',
    templateUrl: './ck-editor-form-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CkEditorFormInputComponent implements FormInputComponent {
    readonly: boolean
    formControl: FormControl
    config: DefaultFormComponentConfig<'ck-editor-form-input'>
}

import gql from 'graphql-tag'

export const GET_NEXT_PRODUCT_STATES = gql`
    query GetNextProductStates($id: ID!) {
        nextProductStates(id: $id)
    }
`

export const TRANSITION_PRODUCT_TO_STATE = gql`
    mutation TransitionProductToState($id: ID!, $state: String!) {
        transitionProductToState(id: $id, state: $state) {
            ... on Product {
                id
                customFields {
                    state
                }
            }
            ... on ProductStateTransitionError {
                errorCode
                message
                transitionError
                fromState
                toState
            }
        }
    }
`

export const extensionRoutes = [  {
    path: 'extensions/pages',
    loadChildren: () => import('./extensions/97c899af43950bd3ad402087607d95a3d5b59be384a98b3f7a810fc645cb36fc/pages-ui-lazy.module').then(m => m.PagesUiLazyModule),
  },
  {
    path: 'extensions/ebics',
    loadChildren: () => import('./extensions/0b21dd28a2ddcd59c92ff26df3d5e7a8c77633e16582decc12cc0a3385e3044e/ebics-ui-lazy.module').then(m => m.EbicsUiLazyModule),
  },
  {
    path: 'extensions/testimonials',
    loadChildren: () => import('./extensions/b026a5f4252ad2e29708ccdf64dafcd52e61aa2a407bc2b86b1990c86c068afb/testimonials-ui-lazy.module').then(m => m.TestimonialsUiLazyModule),
  },
  {
    path: 'extensions/reservations',
    loadChildren: () => import('./extensions/b26904ca9b415f1e507de29b11cc1645bd00177018bf088d88a3873dfbecb229/reservations-ui-lazy.module').then(m => m.ReservationsUiLazyModule),
  }];

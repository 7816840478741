import { NgModule } from '@angular/core'
import { registerDashboardWidget, registerFormInputComponent, SharedModule } from '@vendure/admin-ui/core'

import { AddressStateFieldComponent } from './address-state-field/address-state-field.component'
import { AgeStateFieldComponent } from './age-state-field/age-state-field.component'
import { PxlCodeComponent } from './components/pxl-code'
import { Permission } from './generated/ui-types'
import { KycStateFieldComponent } from './kyc-state-field/kyc-state-field.component'
import { KycStateSelectDialogComponent } from './kyc-state-select-dialog/kyc-state-select-dialog.component'
import { OpensanctionsStateFieldComponent } from './opensanctions-state-field/opensanctions-state-field.component'
import { AddressStateDataService } from './providers/address-state-data.service'
import { AgeStateDataService } from './providers/age-state-data.service'
import { KycStateDataService } from './providers/kyc-state-data.service'
import { OpensanctionsStateDataService } from './providers/opensanctions-state-data.service'
import { PxlStateDataService } from './providers/pxl-state-data.service'
import { PxlStateFieldComponent } from './pxl-state-field/pxl-state-field.component'

@NgModule({
    imports: [SharedModule],
    declarations: [
        KycStateFieldComponent,
        OpensanctionsStateFieldComponent,
        PxlStateFieldComponent,
        AgeStateFieldComponent,
        AddressStateFieldComponent,
        KycStateSelectDialogComponent,
        PxlCodeComponent,
    ],
    providers: [
        registerDashboardWidget('customerstate', {
            title: 'Latest customers',
            supportedWidths: [4, 6, 8, 12],
            requiresPermissions: [Permission.ReadCustomer],
            loadComponent: () => import('./widgets/kyc-widget/kyc-widget.component').then(m => m.KycWidgetComponent),
        }),
        KycStateDataService,
        PxlStateDataService,
        OpensanctionsStateDataService,
        AddressStateDataService,
        AgeStateDataService,
        registerFormInputComponent('kyc-state-field', KycStateFieldComponent),
        registerFormInputComponent('pxl-state-field', PxlStateFieldComponent),
        registerFormInputComponent('opensanctions-state-field', OpensanctionsStateFieldComponent),
        registerFormInputComponent('address-state-field', AddressStateFieldComponent),
        registerFormInputComponent('age-state-field', AgeStateFieldComponent),
        registerFormInputComponent('kyc-state-select-dialog', KycStateFieldComponent),
        registerFormInputComponent('pxl-code-link', PxlCodeComponent),
    ],
})
export class CustomerUiExtensionModule {}

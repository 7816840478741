import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import {
    DeleteOrderMutation,
    DeleteOrderMutationVariables,
    OomniumOrderFieldsQuery,
    OomniumOrderFieldsQueryVariables,
    RejectOrderMutation,
    RejectOrderMutationVariables,
} from '../generated/ui-types'
import { DELETE_ORDER, OOMNIUM_ORDER_FIELDS, REJECT_ORDER } from './order-actions.graphql'

@Injectable()
export class OrderActionsDataService {
    constructor(private dataService: DataService) {}

    rejectOrder(orderId: string, reason: string) {
        return this.dataService.mutate<RejectOrderMutation, RejectOrderMutationVariables>(REJECT_ORDER, {
            orderId,
            reason,
        })
    }

    deleteOrder(orderId: string) {
        return this.dataService.mutate<DeleteOrderMutation, DeleteOrderMutationVariables>(DELETE_ORDER, {
            orderId,
        })
    }

    oomniumOrderFields(orderId: string) {
        return this.dataService.query<OomniumOrderFieldsQuery, OomniumOrderFieldsQueryVariables>(OOMNIUM_ORDER_FIELDS, {
            orderId,
        })
    }
}

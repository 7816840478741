import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextOpenSanctionsStates, TransitionOpenSanctionsToState } from '../generated/ui-types'
import { GET_NEXT_OPENSANCTIONS_STATES, TRANSITION_OPENSANCTIONS_TO_STATE } from './opensanctions-state-data.graphql'

@Injectable()
export class OpensanctionsStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query<GetNextOpenSanctionsStates.Query, GetNextOpenSanctionsStates.Variables>(
            GET_NEXT_OPENSANCTIONS_STATES,
            {
                id,
            },
        )
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate<
            TransitionOpenSanctionsToState.Mutation,
            TransitionOpenSanctionsToState.Variables
        >(TRANSITION_OPENSANCTIONS_TO_STATE, {
            id,
            state,
        })
    }
}

import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextProductStates, TransitionProductToState } from '../generated/ui-types'
import { GET_NEXT_PRODUCT_STATES, TRANSITION_PRODUCT_TO_STATE } from './product-state-data.graphql'

@Injectable()
export class ProductStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query<GetNextProductStates.Query, GetNextProductStates.Variables>(
            GET_NEXT_PRODUCT_STATES,
            { id },
        )
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate<TransitionProductToState.Mutation, TransitionProductToState.Variables>(
            TRANSITION_PRODUCT_TO_STATE,
            {
                id,
                state,
            },
        )
    }
}

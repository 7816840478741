import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { CustomDetailComponent, DataService, ModalService, NotificationService } from '@vendure/admin-ui/core'
import { GetOrderQuery } from '@vendure/admin-ui/core/common/generated-types'
import { EMPTY, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { OomniumOrderFieldsQuery } from '../generated/ui-types'
import { OrderActionsDataService } from '../providers/order-actions.service'

@Component({
    templateUrl: './order-actions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderActionsComponent implements CustomDetailComponent, OnInit {
    // These two properties are provided by Vendure and will vary
    // depending on the particular detail page you are embedding this
    // component into.
    entity$: Observable<NonNullable<GetOrderQuery['order']>>
    order: GetOrderQuery['order']
    detailForm: FormGroup
    canBeDeleted = false
    customFields: OomniumOrderFieldsQuery['order']
    customFields$: Observable<NonNullable<OomniumOrderFieldsQuery['order']> | undefined>

    entityId: string

    constructor(
        private dataService: DataService,
        private rejectOrderDataService: OrderActionsDataService,
        private notificationService: NotificationService,
        private modalService: ModalService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.entity$
            .pipe(
                map(entity => {
                    this.entityId = entity.id
                    this.order = entity
                    this.canBeDeleted = !(!!entity.payments?.find(p => p.state === 'Settled') || false)
                }),
            )
            .subscribe()

        this.customFields$ = this.route.data.pipe(
            switchMap(data => data.entity),
            switchMap((entity: any) => {
                return this.rejectOrderDataService.oomniumOrderFields(entity.id as string).mapStream(data => {
                    this.customFields = data.order
                    return data.order
                })
            }),
        )
    }

    rejectOrder(reason: string) {
        this.modalService
            .dialog({
                title: 'Really cancel (and refund) order?',
                buttons: [
                    { type: 'secondary', label: 'No' },
                    { type: 'danger', label: 'Yes', returnValue: true },
                ],
            })
            .pipe(
                switchMap(response =>
                    response ? this.rejectOrderDataService.rejectOrder(this.entityId, reason) : EMPTY,
                ),
            )
            .subscribe(
                ({ rejectOrder }) => {
                    if (rejectOrder?.success) {
                        if (rejectOrder?.message) {
                            this.notificationService.success(rejectOrder?.message)
                            return
                        }
                        this.notificationService.success('Order cancelled')
                        // lazy way of refreshing the page
                        window.location.reload()
                    } else {
                        this.notificationService.error(rejectOrder?.message || 'An error occurred')
                    }
                },
                () => {
                    this.notificationService.error(_('common.notify-delete-error'), {
                        entity: 'Order',
                    })
                },
            )
    }

    deleteOrder() {
        this.modalService
            .dialog({
                title: "Really delete order? (can't be undone!)",
                buttons: [
                    { type: 'secondary', label: 'No' },
                    { type: 'danger', label: 'Yes', returnValue: true },
                ],
            })
            .pipe(switchMap(response => (response ? this.rejectOrderDataService.deleteOrder(this.entityId) : EMPTY)))
            .subscribe(
                ({ deleteOrder }) => {
                    if (deleteOrder?.success) {
                        if (deleteOrder?.message) {
                            this.notificationService.success(deleteOrder?.message)
                            return
                        }
                        this.notificationService.success('Order deleted. Please go back to overview.')
                    } else {
                        this.notificationService.error(deleteOrder?.message || 'An error occurred')
                    }
                },
                () => {
                    this.notificationService.error(_('common.notify-delete-error'), {
                        entity: 'Order',
                    })
                },
            )
    }
}

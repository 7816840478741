import { Injectable } from '@angular/core'
import { DataService } from '@vendure/admin-ui/core'

import { GetNextPxlStates, TransitionPxlToState } from '../generated/ui-types'
import { GET_NEXT_PXL_STATES, TRANSITION_PXL_TO_STATE } from './pxl-state-data.graphql'

@Injectable()
export class PxlStateDataService {
    constructor(private dataService: DataService) {}

    getNextStates(id: string) {
        return this.dataService.query<GetNextPxlStates.Query, GetNextPxlStates.Variables>(GET_NEXT_PXL_STATES, {
            id,
        })
    }

    transitionToState(id: string, state: string) {
        return this.dataService.mutate<TransitionPxlToState.Mutation, TransitionPxlToState.Variables>(
            TRANSITION_PXL_TO_STATE,
            {
                id,
                state,
            },
        )
    }
}

import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { DataService, FormInputComponent, ModalService, NotificationService } from '@vendure/admin-ui/core'
import { EMPTY, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { Customer, CustomFieldConfig } from '../generated/ui-types'
import { KycStateSelectDialogComponent } from '../kyc-state-select-dialog/kyc-state-select-dialog.component'
import { AddressStateDataService } from '../providers/address-state-data.service'

@Component({
    templateUrl: './address-state-field.component.html',
    styleUrls: ['./address-state-field.component.scss'],
})
export class AddressStateFieldComponent implements FormInputComponent, OnInit {
    readonly: boolean
    nextStates$: Observable<string[]>
    config: Observable<CustomFieldConfig>
    entityId: string

    formControl: FormControl

    constructor(
        private dataService: DataService,
        private addressStateDataService: AddressStateDataService,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
    ) {}

    async manuallyTransitionToState() {
        return this.modalService
            .fromComponent(KycStateSelectDialogComponent, {
                locals: {
                    nextStates: this.nextStates$,
                    cancellable: true,
                    message:
                        'Manually transition the kyc to another state. Note that order states are governed by rules which may prevent certain transitions.',
                },
                closable: true,
                size: 'md',
            })
            .pipe(
                switchMap(result => {
                    if (result) {
                        return this.transitionToStateOrThrow(this.entityId, result)
                    } else {
                        return EMPTY
                    }
                }),
            )
            .subscribe()
    }
    private transitionToStateOrThrow(orderId: string, state: string) {
        return this.addressStateDataService.transitionToState(orderId, state).pipe(
            map(({ transitionAddressToState }) => {
                switch (transitionAddressToState?.__typename) {
                    case 'Customer':
                        return (transitionAddressToState as Customer)?.customFields?.kycState
                    case 'OomniumCustomerStateTransitionError':
                        this.notificationService.error(transitionAddressToState?.transitionError)
                        throw new Error(transitionAddressToState?.transitionError)
                }
            }),
        )
    }

    ngOnInit() {
        this.nextStates$ = this.route.data.pipe(
            switchMap(data => data.entity),
            switchMap((entity: any) => {
                this.entityId = entity.id
                return this.addressStateDataService.getNextStates(entity.id as string).mapStream(data => {
                    return data.nextAddressStates
                })
            }),
        )
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, ViewChild } from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular'
import { CKEditor5 } from '@ckeditor/ckeditor5-angular/ckeditor'
import { CustomFieldConfigType, CustomFieldControl } from '@vendure/admin-ui/core'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor'

@Component({
    selector: 'vdr-ck-editor',
    template: `<ckeditor
        #editor
        [config]="EditorConfig"
        [editor]="Editor"
        [data]="value"
        (ready)="onReady($event)"
        (change)="onEditorChange($event)"
    ></ckeditor> `,
    styles: [``],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: VdrCkEditorComponent,
            multi: true,
        },
    ],
})
export class VdrCkEditorComponent implements CustomFieldControl, ControlValueAccessor {
    config: CustomFieldConfigType
    @Input() formControl: FormControl
    @ViewChild('editor') editorComponent: CKEditorComponent

    onChange: (val: any) => void
    onTouch: () => void
    value: string
    @HostBinding('class.readonly')
    _readonly = false

    @Input() set readonly(value: any) {
        this._readonly = !!value
        this.setEditorReadOnly(this.getEditor())
    }

    private setEditorReadOnly(editor: CKEditor5.Editor | null) {
        if (this._readonly) {
            editor?.enableReadOnlyMode('lock')
        } else {
            editor?.disableReadOnlyMode('lock')
        }
    }
    setDisabledState(isDisabled: boolean) {
        this.readonly = isDisabled
    }

    public getEditor() {
        // Warning: This may return "undefined" if the editor is hidden behind the `*ngIf` directive or
        // if the editor is not fully initialised yet.
        return this.editorComponent?.editorInstance
    }
    public onEditorChange({ editor }: ChangeEvent) {
        const data = editor?.getData()
        this.onChange(data)
        this.changeDetector.markForCheck()
    }

    public onReady(editor: CKEditor5.Editor) {
        if (this.value) {
            editor.setData(this.value)
        }
        this.setEditorReadOnly(editor)
    }

    public EditorConfig: CKEditor5.Config = {
        htmlSupport: {
            allow: [
                // Enables <div>s with all classes
                {
                    name: 'div',
                    classes: true,
                },
                {
                    name: 'p',
                    classes: true,
                },
                {
                    name: 'video',
                    attributes: true,
                    classes: true,
                    styles: true,
                },
            ],
        },
        toolbar: {
            shouldNotGroupWhenFull: true,
            items: [
                'heading',
                '|',
                'bold',
                'italic',
                'subscript',
                'superscript',
                'underline',
                'alignment',
                'blockQuote',
                'removeFormat',
                'link',
                '|',

                'bulletedList',
                'numberedList',
                'outdent',
                'indent',
                '|',
                'undo',
                'redo',
                '-',
                'horizontalLine',
                'pageBreak',
                '|',
                'insertTable',
                'imageUpload',
                'mediaEmbed',
                '|',
                'findAndReplace',
                '|',
                'htmlEmbed',
                'sourceEditing',
            ],
        },
        mediaEmbed: { previewsInData: true },
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            ],
        },
        simpleUpload: {
            uploadUrl: '/utilities/asset',
            withCredentials: true,
        },
        image: {
            toolbar: [
                'imageTextAlternative',
                'toggleImageCaption',
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:side',
                'resizeImage',
                'linkImage',
            ],
            resizeUnit: '%',
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    label: '100%',
                    value: null,
                },
                {
                    name: 'resizeImage:50',
                    value: '50',
                },
            ],
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties'],
        },
        link: {
            decorators: {
                addTargetToExternalLinks: {
                    mode: 'automatic',
                    callback: (url: string) => /^(https?:)?\/\//.test(url) || url.toLowerCase().endsWith('.pdf'),
                    attributes: {
                        target: '_blank',
                        rel: 'noopener',
                    },
                },

                /*   openInNewTab: {
                    mode: 'manual',
                    label: 'Open in a new tab',
                    defaultValue: false, // This option will be selected by default.
                    attributes: {
                        target: '_blank',
                        rel: 'noopener',
                    },
                },*/
            },
        },
    }

    public Editor = Editor as any

    constructor(private changeDetector: ChangeDetectorRef) {}

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }

    writeValue(value: any) {
        if (value !== this.value) {
            this.value = value
            this.getEditor()?.setData(value)
        }
    }
}

import { NgModule } from '@angular/core'
import { addNavMenuItem, registerDashboardWidget, registerFormInputComponent } from '@vendure/admin-ui/core'

import { ReservationCountLinkComponent } from './components/reservation-count-link/reservation-count-link.component'
import { Permission } from './generated/ui-types'
import { ReservationsSharedModule } from './reservations-shared.module'

@NgModule({
    imports: [ReservationsSharedModule],
    declarations: [ReservationCountLinkComponent],
    providers: [
        registerFormInputComponent('reservations-count-link', ReservationCountLinkComponent),
        addNavMenuItem(
            {
                id: 'reservations',
                label: 'Reservations',
                routerLink: ['/extensions/reservations'],
                icon: 'star',
            },
            'catalog',
        ),
        registerDashboardWidget('reservations', {
            title: 'Latest reservations',
            requiresPermissions: [Permission.ReadCustomer],
            supportedWidths: [4, 6, 8, 12],
            loadComponent: () =>
                import('./widgets/reservations-widget/reservations-widget.component').then(
                    m => m.ReservationsWidgetComponent,
                ),
        }),
    ],
    exports: [],
})
export class ReservationsUiExtensionModule {}

import { NgModule } from '@angular/core'
import { registerCustomDetailComponent, registerDashboardWidget, SharedModule } from '@vendure/admin-ui/core'

import { Permission } from './generated/ui-types'
import { OrderActionsComponent } from './order-actions/order-actions.component'
import { OrderActionsDataService } from './providers/order-actions.service'

@NgModule({
    imports: [SharedModule],
    declarations: [OrderActionsComponent],
    providers: [
        OrderActionsDataService,
        registerCustomDetailComponent({ component: OrderActionsComponent, locationId: 'order-detail' }),
        registerDashboardWidget('latestOrdersOomnium', {
            title: 'Latest orders',
            requiresPermissions: [Permission.ReadOrder],
            supportedWidths: [4, 6, 8, 12],
            loadComponent: () =>
                import('./widgets/latest-orders-widget/latest-orders-widget.component').then(
                    m => m.OomniumLatestOrdersWidgetComponent,
                ),
        }),
    ],
})
export class OrderUiExtensionModule {}

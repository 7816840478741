import { NgModule } from '@angular/core'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular'
import { addNavMenuSection, registerFormInputComponent, SharedModule } from '@vendure/admin-ui/core'

import { CkEditorFormInputComponent } from './components/ck-editor-form-input/ck-editor-form-input.component'
import { VdrCkEditorComponent } from './components/vdr-ck-editor/vdr-ck-editor'
import { Permission } from './generated/ui-types'

@NgModule({
    imports: [SharedModule, CKEditorModule],
    providers: [
        addNavMenuSection(
            {
                id: 'Pages',
                label: 'Oomnium',
                requiresPermission: Permission.ReadPages,
                items: [
                    {
                        id: 'Pages',
                        label: 'Pages',
                        routerLink: ['/extensions/pages/pages'],
                        // Icon can be any of https://clarity.design/icons
                        icon: 'book',
                        requiresPermission: Permission.ReadPages,
                    },
                    {
                        id: 'Sections',
                        label: 'Sections',
                        routerLink: ['/extensions/pages/sections'],
                        icon: 'tag',
                        requiresPermission: Permission.ReadPages,
                    },
                ],
            },
            // Add this section before the "settings" section
            'settings',
        ),
        registerFormInputComponent('vdr-ck-editor', VdrCkEditorComponent),
        registerFormInputComponent('ck-editor-form-input', CkEditorFormInputComponent),
    ],
    declarations: [VdrCkEditorComponent, CkEditorFormInputComponent],
    exports: [VdrCkEditorComponent, CkEditorFormInputComponent],
})
export class PagesUiExtensionModule {}

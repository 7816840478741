import { NgModule } from '@angular/core'
import {
    addActionBarItem,
    registerDashboardWidget,
    registerFormInputComponent,
    SharedModule,
} from '@vendure/admin-ui/core'
import { Product } from '@vendure/core'

import { Permission } from './generated/ui-types'
import { ProductStateFieldComponent } from './product-state-field/product-state-field.component'
import { ProductStateSelectDialogComponent } from './product-state-select-dialog/product-state-select-dialog.component'
import { ProductStateDataService } from './providers/product-state-data.service'

@NgModule({
    imports: [SharedModule],
    declarations: [ProductStateFieldComponent, ProductStateSelectDialogComponent],
    providers: [
        registerDashboardWidget('campaignStats', {
            title: 'Campaign Stats',
            supportedWidths: [6, 8, 12],
            requiresPermissions: [Permission.ReadProduct],
            loadComponent: () =>
                import('./widgets/stats-widget/stats-widget.component').then(m => m.StatsWidgetComponent),
        }),
        ProductStateDataService,
        registerFormInputComponent('product-state-field', ProductStateFieldComponent),
        registerFormInputComponent('product-state-select-dialog', ProductStateFieldComponent),
        addActionBarItem({
            id: 'print-invoice',
            label: 'Recalculate',
            locationId: 'product-detail',
            requiresPermission: Permission.UpdateProduct,
            onClick: async (event, context) => {
                context.route.snapshot.data.entity.subscribe(async (product: Product) => {
                    const url = `/product/recalculate/${product.id}`
                    await fetch(url)
                    window.location.reload()
                })
            },
            buttonStyle: 'outline',
        }),
        addActionBarItem({
            id: 'presaleKey',
            label: 'presaleKey',
            locationId: 'product-detail',
            requiresPermission: Permission.ReadProduct,
            onClick: async (event, context) => {
                context.route.snapshot.data.entity.subscribe((product: Product) => {
                    window.location.pathname = `/product/presaleKey/${product.id}`
                })
            },
            buttonStyle: 'outline',
        }),
        addActionBarItem({
            id: 'investorsForAktioniariat',
            label: 'Aktionariat XLS',
            locationId: 'product-detail',
            requiresPermission: Permission.ReadOrder,
            onClick: async (event, context) => {
                context.route.snapshot.data.entity.subscribe((product: Product) => {
                    window.location.pathname = `/product/investorsForAktioniariat/${product.id}`
                })
            },
            buttonStyle: 'outline',
        }),
        addActionBarItem({
            id: 'investors',
            label: 'Investors XLS',
            locationId: 'product-detail',
            requiresPermission: Permission.ReadOrder,
            onClick: async (event, context) => {
                context.route.snapshot.data.entity.subscribe((product: Product) => {
                    window.location.pathname = `/product/investors/${product.id}`
                })
            },
            buttonStyle: 'outline',
        }),
    ],
})
export class ProductUiExtensionModule {
    constructor() {
        // dirty hack to set the color of the header bar depending on environment
        // the better way would be via compile-admin-ui script and include different css files
        // but then we need to compile the admin ui for each environment, which slows us down for not much benefit
        let color: string | null = null
        switch (window.location.hostname) {
            case 'backend.cluster.oomnium.com':
                return
            case 'backend-stage.cluster.oomnium.com':
                color = '#4b75de'
                break
            case 'backend-develop.cluster.oomnium.com':
                color = '#de4b57'
                break
            default:
                color = '#4bde8a'
        }
        if (color) {
            document.body.setAttribute('style', ` --color-header-gradient-from: ${color};`)
        }
    }
}

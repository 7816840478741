import gql from 'graphql-tag'

export const GET_NEXT_KYC_STATES = gql`
    query GetNextKycStates($id: ID!) {
        nextKycStates(id: $id)
    }
`

export const TRANSITION_KYC_TO_STATE = gql`
    mutation TransitionKycToState($id: ID!, $state: String!) {
        transitionKycToState(id: $id, state: $state) {
            ... on Customer {
                id
                customFields {
                    kycState
                }
            }
            ... on OomniumCustomerStateTransitionError {
                errorCode
                message
                transitionError
                fromState
                toState
            }
        }
    }
`
